import React from 'react';
import {style} from './BurgerButton.scss';


export class BurgerButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dummy = style;
  }

  render() {
    return (
      <button className={`burger-button ${this.props.close ? 'close' : ''}`} onClick={this.props.onClick}>
        <div className="line1"/>
        <div className="line2"/>
        <div className="line3"/>
      </button>
    );
  }
}
