import React from 'react';
import {style} from './Icon.scss';


export class Icon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.dummy = style;
  }

  render() {
    const iconData = PATHS[this.props.value] || {size: 18};
    const s = iconData.size || Math.max(iconData.width, iconData.height);
    iconData.width = iconData.width || s;
    iconData.height = iconData.height || s;
    const ex = `M-.3 0L${s} ${s + .3} ${s + .3} ${s} 0 -.3 M -.3 ${s} L${s} -.3 ${s + .3} 0 0 ${s + .3}z`;
    iconData.path = iconData.path || ex;
    const debug = this.props.debug;
    iconData.viewBox = iconData.viewBox || `${(iconData.width - s) / 2}, ${(iconData.height - s) / 2}, ${iconData.width}, ${iconData.height}`;
    return (
      <span className={`Icon Icon-${this.props.value} ${this.props.className}`}>
        <svg xmlns="http://www.w3.org/2000/svg"
             width={`${s}`} height={`${s}`}
             viewBox={iconData.viewBox}
        >
          {iconData.content ? iconData.content :
            <path d={iconData.path}>
              {/*<animate attributeName="d" begin="0s" dur="1s" repeatCount="indefinite" from={ex} to={iconData.path}/>*/}
            </path>
          }
          {debug ? <path d={ex}/> : ''}
        </svg>
      </span>
    );
  }
}


const PATHS = {
    enter: {size: 24, path: 'M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z'},
    left: {size: 24, path: 'M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'},
    down: {size: 24, path: 'M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z'},
    right: {size: 24, path: 'M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'},
    space: {size: 24, path: 'M18 9v4H6V9H4v6h16V9z'},
    check: {size: 18, path: 'M6.61 11.89L3.5 8.78 2.44 9.84 6.61 14l8.95-8.95L14.5 4z'},
    facebook: {
      size: 1792,
      path: 'M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z'
    },
    twitter: {
      size: 1792,
      path: 'M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z.5t277-102.5q147 0 228 12z'
    },
    instagram: {
      size: 1792,
      path: 'M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z.5t277-102.5q147 0 228 12z'
    },
    innovationPin: {
      width: 299,
      height: 455,
      content: <g>
        <circle fill="none" cx="142.09" cy="141.55" r="58.67"/>
        <path
          d="M295.67,477.2l96.38,97.4,96.66-96.66a142.09,142.09,0,1,0-193-.74Zm96.92-163.1a58.67,58.67,0,1,1-58.67,58.67A58.67,58.67,0,0,1,392.59,314.11Z"
          transform="translate(-250.5 -231.22)"/>
        <path
          d="M548.56,596.65c-2.67-4.89-11.08-8.79-21.45-9.93-13.43-1.48-25.67,2.21-29.12,8.77a7.11,7.11,0,0,0-.72,2.15l-91.13,5.52c-4.14-3.17-10.58-5.51-17.94-6.32a45,45,0,0,0-18.13,1.57l-51.91-21.11a7.22,7.22,0,0,0,.95-1.44h0a5.19,5.19,0,0,0-.49-5.31c-2.51-3.68-9.93-6.3-18.89-6.68-11.48-.49-21.68,2.93-24.28,8.12a5.27,5.27,0,0,0,.41,5.48c2.45,3.68,9.34,6.19,18,6.55q1,0,2.1,0a49.44,49.44,0,0,0,13.26-1.75L359.4,605.1a11,11,0,0,0-1.15,1.81,8.12,8.12,0,0,0,.31,7.65,16.14,16.14,0,0,0,6.47,6q-9.48,8.59-19,17.29-9.23,8.47-18.42,17l-.87-.11c-14.92-1.65-29.09,3.43-33,11.79a9.41,9.41,0,0,0,.5,8.77c3.23,5.72,11.77,10,22.29,11.16a54.68,54.68,0,0,0,6,.33c13.29,0,25.13-4.85,28.31-12.07a9.09,9.09,0,0,0-.6-8.48,17.82,17.82,0,0,0-6.07-5.89l34.42-35.73.44.06a52.27,52.27,0,0,0,5.72.31c12,0,22.58-4.13,25.45-10.33a8,8,0,0,0,.35-.88l90.62-7.8c3.78,3,9.91,5.24,17.2,6a53.44,53.44,0,0,0,5.84.31c10.59,0,21.47-3,24.48-9.05A7,7,0,0,0,548.56,596.65Z"
          transform="translate(-250.5 -231.22)"/>
      </g>
    },
    parkPin: {
      width: 293,
      height: 354,
      content: <g>
        <path
          d="M400,229A146.49,146.49,0,0,0,300.08,482.6L399.45,583l99.66-99.66A146.49,146.49,0,0,0,400,229Zm65.5,161.09q-6.52,8.21-19.44,13.4t-30.55,5.19h-25.6v65.92H348.63V305.08h66.89q29.46,0,45.88,15.21T477.81,357Q477.81,374.14,465.5,390.07Z"
          transform="translate(-253.5 -228.98)"/>
        <path d="M415.52,336.47h-25.6v40.81h25.6q9.42,0,14.85-5.55t5.43-14.61q0-9.06-5.55-14.85T415.52,336.47Z"
              transform="translate(-253.5 -228.98)"/>
      </g>,
    },
    bikePin: {
      width: 290,
      height: 350,
      content: <g>
        <path
          d="M303.05,405.85a29.18,29.18,0,1,0,52.66-17.31l-14.43,23a10.56,10.56,0,0,1-9,5h0a10.62,10.62,0,0,1-9-16.26l14.47-23.07a29.17,29.17,0,0,0-34.7,28.65Z"
          transform="translate(-255 -229.73)"/>
        <path
          d="M400,229.73a145,145,0,0,0-98.9,251l98.35,99.39,98.64-98.64A145,145,0,0,0,400,229.73Zm-51.22,84.42h31.77a10.62,10.62,0,1,1,0,21.24H348.78a10.62,10.62,0,1,1,0-21.24Zm119,142A50.4,50.4,0,0,1,439,364.32l-.59-1.43H371.79l-4.33,6.91a50.2,50.2,0,1,1-18-11.34l7.42-11.83a10.56,10.56,0,0,1,9-5H429.6l-6.05-14.61A10.62,10.62,0,0,1,429.79,313l19.52-7a10.62,10.62,0,0,1,7.13,20l-9,3.21,11.14,26.89a50.42,50.42,0,1,1,9.18,100Z"
          transform="translate(-255 -229.73)"/>
        <path
          d="M467.76,434.88a29.19,29.19,0,0,0,0-58.37l-.73,0,10.48,25.31A10.62,10.62,0,1,1,457.89,410l-10.44-25.2a29.17,29.17,0,0,0,20.31,50.12Z"
          transform="translate(-255 -229.73)"/>
      </g>
    },
    logo: {
      width: 906,
      height: 906,
      viewBox: '7494 517 906 906',
      path: 'm7494 517v589c47-11 95-17 146-17 25 0 50 2 74 4l90-345 21 5-89 343c200 30 368 154 460 327h204v-906zm675 161-25-6 25-6 7-25 6 25 25 6-25 6-6 25zm-181 30-16-5 16-4 5-16 4 16 16 4-16 5-4 16zm-186 217 37-142 118 60-23 88zm438-12-12-3 12-3 3-13 4 13 12 3-12 3-4 13zm-299 64 17-64 141 71z',
    },
    calendar: {
      width: 773,
      height:
        686,
      content:
        <g>
          <path d="M665.839,49.198V-1h-51.931v50.198H496.014V-1h-51.931v50.198H326.19V-1h-51.931v50.198H156.365V-1h-51.931v50.198H0
		v636.99h773.736V49.198H665.839z M721.804,634.257H51.931V101.129h52.503v39.809h51.931v-39.809h117.894v39.809h51.931v-39.809
		h117.893v39.809h51.931v-39.809h117.894v39.809h51.931v-39.809h55.965V634.257z"/>
          <rect x="104.434" y="341.728" width="51.931" height="51.931"/>
          <rect x="274.258" y="341.728" width="51.931" height="51.931"/>
          <rect x="444.083" y="341.728" width="51.931" height="51.931"/>
          <rect x="613.908" y="341.728" width="51.931" height="51.931"/>
          <rect x="104.434" y="495.782" width="51.931" height="51.931"/>
          <rect x="274.258" y="495.782" width="51.931" height="51.931"/>
          <rect x="444.083" y="495.782" width="51.931" height="51.931"/>
          <rect x="613.908" y="495.782" width="51.931" height="51.931"/>
        </g>,
    }
    ,
    close: {
      size: 18,
      path:
        'M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'
    }
    ,
    mail: {
      width: 835,
      height:
        686,
      path:
        'M0,0v686.188h835V0H0z M756.183,45L414.309,386.874L72.435,45H756.183z M45,641.188V81.205l369.309,369.309L790,74.823 v566.366H45z'
    }
  }
;
