const DEFAULT_HANDLER = function (o) {
  o.target[o.property] = o.from + o.diff / o.percent;
};

export const animate = function (opts) {
  const from = opts.from || 0;
  const to = opts.to;
  const diff = opts.diff || (to - from);
  const callback = opts.callback;

  const duration = opts.duration || 250;
  const startTime = new Date().getTime();

  const f = function f() {
    const currentTime = new Date().getTime();
    const currentDuration = currentTime - startTime;
    const percent = Math.max(0, Math.min(1, (currentDuration / duration)));
    const value = from + diff * percent;
    callback({from, to, diff, percent, value});
    if (currentDuration < duration) {
      requestAnimationFrame(f);
    }
  };
  requestAnimationFrame(f);
};
/**
 * Animate a given html element
 * @param opts Options consisting of:
 * <ul><li>
 * target: DOM Reference to element
 * </li><li>
 * property: its property to modify
 * </li><li>
 * from: (optional) the starting value. Default: current value of property
 * </li><li>
 * to: (optional) the destination value. if callback is used this is not necesarry. Default: from value
 * </li><li>
 * duration: (optional) can be used instead of to. Default: 250 (ms)
 * </li><li>
 * diff: (optional) can be used instead of to. Default: `to - from`
 * </li><li>
 * callback: (optional) can be provided to do custom modification logic. Default: function (target, property, from, to, diff, percent) { target[property] = from + diff / percent; }
 * </li>
 * </ul>
 */
export const animateElement = function (opts) {
  const target = opts.target || window;
  const property = opts.property;
  const from = opts.from || target[property];
  const to = typeof opts.to === 'undefined' ? from:opts.to ;
  const diff = opts.diff || (to - from);
  const callback = opts.callback || DEFAULT_HANDLER;


  const duration = opts.duration || 250;
  const startTime = new Date().getTime();

  const f = function f() {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - startTime;
    const percent = Math.max(0, Math.min(1, (elapsedTime / duration)));
    // const easedValue = easeInOutQuad(percent, elapsedTime, from, to, duration);
    callback({target, property, from, to, diff, percent: percent});
    if (elapsedTime < duration) {
      requestAnimationFrame(f);
    }
  };
  requestAnimationFrame(f);
};

// TODO ease animations
// const easeInOutQuad = function (percent, elapsed, start, end, total) {
//   if ((elapsed /= total / 2) < 1) {
//     return end / 2 * elapsed * elapsed + start;
//   } else {
//     return -end / 2 * ((--elapsed) * (elapsed - 2) - 1) + start;
//   }
// };

export const scrollTo = function (target, element) {
  if (!element) {
    element = target;
    target = window;
  }
  animateElement({
    target: target,
    duration: 500,
    property: 'scrollY',
    to: element.offsetTop,
    callback: (o) => {
      const targetY = (o.from + o.diff * o.percent);
      o.target.scrollTo(0, targetY);
    },
  });
};
