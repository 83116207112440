import React from "react";
import {FixedHeader} from './FixedHeader';
import {BurgerMenu} from './BurgerMenu';
import style from './Menu.scss';
import {Logo} from "./Logo";
import {Link} from "react-router-dom";

export class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dummy = style;
    this.renderMenuElement = this.renderMenuElement.bind(this);
  }

  renderMenuElement(site) {
    const currentPath = this.props.location.pathname;
    const activeClass = site.path === currentPath || (site.path !== '/' && currentPath.indexOf(site.path) !== -1)
      ? 'selected' : '';
    return (<li
      className={`${site.className}-menu-element ${activeClass} menu-element`}
      key={site.path}>
      {site.path.startsWith('#') ? <a href={site.path}>{site.label}</a> : <Link to={`${site.path}`}>{site.label}</Link>}
    </li>);
  }

  render() {
    return (
      <div className="Menu">

        <div className="container">
          <Logo icon/>
          <ul className="static menu">
            {this.props.links.map(this.renderMenuElement)}
            {/*<li className="menu-element language-switcher-wrapper">*/}
            {/*<LanguageSwitcher/>*/}
            {/*</li>*/}
          </ul>
          {this.props.mobileSize ? null : <FixedHeader {...this.props} />}
        </div>
        {this.props.mobileSize ? <BurgerMenu {...this.props} /> : null}
      </div>);
  }
}
