import React from 'react';
import {cacheScript} from './ScriptCache';
import {GoogleApi} from './GoogleAppi';
import * as style from './Map.scss';

export class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      map: null,
      google: null,
    };
    this.dummy = style;
    this.markers = [];
    this.renderPins.bind(this);
    this.preloadMarkerImage.bind(this);
  }

  preloadMarkerImage(cb) {
    if (!this.props.pinImage || this.markerImage) {
      cb();
    }
    const img = new Image();
    img.onload = () => {
      this.markerImage = {
        url: this.props.pinImage.imageUrl,
        size: new google.maps.Size(img.naturalWidth, img.naturalHeight),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(this.props.pinImage.anchorX, this.props.pinImage.anchorY),
      };
      document.querySelector('body').removeChild(img);
      cb();
    };
    img.src = this.props.pinImage.imageUrl;
    document.querySelector('body').appendChild(img);
  }

  ensurePinIds() {
    this.props.pins.forEach(pin => {
      pin._id = pin._id || JSON.stringify(pin);
    });
  }

  removeObsoletePins() {
    const pinIds = this.props.pins.map(pin => pin._id);

    if (this.markers) {
      this.markers.forEach(pin => {
        if (pinIds.indexOf(pin._data._id) == -1) {
          pin.setMap(null);
        }
      });
    }
  }

  addNewPins() {
    const pinIds = this.markers.map(marker => marker._data._id);

    this.props.pins.forEach(pin => {
      if (pinIds.indexOf(pin._id) !== -1) {
        return;
      }

      const marker = new this.maps.Marker({
        position: {lat: parseFloat(pin.lat), lng: parseFloat(pin.lng)},
        icon: this.markerImage,
        title: pin.title,
        map: this.map,
      });

      this.maps.event.addListener(marker, 'click', () => {
        this.markerClicked(marker, pin);
      });
      marker._data = pin;
      this.markers.push(marker);
    });
  }

  markerClicked(marker, pinData) {
    // this.infowindow.setContent('<b>' + pinData.title + '</b>');
    // this.infowindow.open(this.map, marker);
    if (this.props.onPinClick) {
      this.props.onPinClick(pinData);
    }
  }

  renderPins() {

    if (!this.map || !this.maps || !this.props.pins) {
      return;
    }
    // if (!this.infowindow) {
    //   this.infowindow = new this.maps.InfoWindow({
    //     content: '#YOLO',
    //   });
    // }
    this.preloadMarkerImage(() => {
      this.ensurePinIds();
      this.removeObsoletePins();
      this.addNewPins();
    });

    if (this.updateRequested) {
      this.updateRequested = false;
      this.forceUpdate();
    }
    const map = this.map;
  }


  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }
    this.scriptCache = cacheScript({
      google: GoogleApi({
        apiKey: 'AIzaSyAQb_V0YMtJWcdqXDYbOFYiywIEAGyhtvw',
        libraries: ['initMap'],
      }),
    });
    this.scriptCache.google.onLoad((err, tag) => {
      let center;
      if (this.props.center) {
        center = this.props.center;
      } else if (this.props.pins) {
        center = this.props.pins.reduce((s, p) => ({
          lat: s.lat + p.lat / this.props.pins.length,
          lng: s.lng + p.lng / this.props.pins.length,
        }), {lat: 0, lng: 0});
      } else {
        center = {lat: 48.1518476, lng: 11.5753626};
      }
      this.maps = google.maps;
      this.map = new google.maps.Map(this.refs.mymap, {
        center,
        zoom: this.props.zoom || 17,
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [
              {saturation: -100},
            ],
          },
        ],
      });
      this.renderPins();
    });
  }

  render() {
    clearTimeout(this.pinTimeout);
    this.pinTimeout = setTimeout(() => this.renderPins(), 500);

    return (
      <div className="Map" ref="mymap"/>
    );
  }
}
