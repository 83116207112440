/**
 * Object Extending Functionality (as seen in _ or lodash)
 */
export const extend = function (out) {
  out = out || {};
  for (var i = 1; i < arguments.length; i++) {
    if (!arguments[i])
      continue;

    for (var key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key))
        out[key] = arguments[i][key];
    }
  }
  return out;
};

export const serializeForm = function (element) {
  return Array.prototype.reduce.call(
    element.elements,
    (s, e) => {
      s[e.name] = e.value;
      return s;
    },
    {}
  );
};
