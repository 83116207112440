import React from 'react';
import * as style from './StringInput.scss';

const TYPE_TO_INPUT_TYPE_MAP = {
  Email: 'email',
  Phone: 'tel',
  Password: 'password',
  Number: 'number',
};

export class StringInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {valid: true};
    this.dummy = style;
  }

  onChange(e) {
    const value = e.target.value;
    const valid = this.validate(value);
    this.setState({value});
    this.props.onChange({value, valid});
  }

  validate(value) {
    let valid = null;
    if (this.props.validator) {
      valid = !!value && this.props.validator(value);
      this.setState({valid});
    }
    return valid;
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.forceValidation && newProps.forceValidation) {
      this.validate(this.props.value);
    }
  }

  render() {
    const value = typeof this.props.value !== 'undefined' && this.props.value !== null ?
      this.props.value : this.props.defaultValue;
    const groupClass = `form-group ${this.props.fieldClasses.groupClass}`;
    const hasValueClass = value ? 'has-value' : 'empty';
    const focusClass = this.state.focused ? 'focused' : 'blurred';
    return (
      <div
        className={`${this.props.name} StringInput ${groupClass} ${hasValueClass} ${focusClass}`}>
        <label
          className={`${this.props.fieldClasses.labelClass} form-control-label`}
          htmlFor={`${this.props.name}-form-control`}>
          {this.props.entityDefinition.label || this.props.name}
          {this.props.required ? <span className="required">*</span> : null}
        </label>
        <div className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
          <input
            type={TYPE_TO_INPUT_TYPE_MAP[this.props.entityDefinition._type] || 'text'}
            name={this.props.name}
            id={`${this.props.name}-form-control`}
            className={`${this.props.name} form-control ${this.props.fieldClasses.inputClass}`}
            placeholder={this.props.entityDefinition.placeholder}
            onFocus={e => this.setState({focused: true})}
            onBlur={e => this.setState({focused: false})}
            value={value}
            onChange={e => this.props.onChange(e.target.value)}
          />
          {this.state.valid ? null :
            <small
              className="validationMessage">
              {this.props.validationMessage || `Please supply a valid ${this.props.entityDefinition.label}`}
            </small>
          }
          {this.props.entityDefinition.details ?
            <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null
          }
        </div>
      </div>
    );
  }
}

StringInput.defaultProps = {
  name: 'text',
  entityDefinition: {
    label: 'text',
  },
  value: '',
  defaultValue: '',
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  placeholder: '',
  onChange: (v) => console.log(v),
};
