import {createStore} from 'cartiv';
import {backend} from '../../backend_api';
import {api} from '../../api';
import {clone} from '../../lib/clone';

export const ContactFormStore = createStore({
  api,
  name: 'ContactForm',
}, {
  getInitialState() {
    return {
      sendStatus: 'NEW',
      error: null,
      result: null,
    };
  },
  onSendContactForm(contactData) {
    this.setState({sendStatus: 'SENDING'});
    const data = {
      to: 'dummy@mail.com',
      dataContext: {
        'name': contactData.name,
        'email': contactData.email,
        'phone': contactData.phone,
        'message': contactData.message,
        'actionUrl': 'mailto:' + contactData.email,
        'actionLabel': 'Reply',
        'previewText': '',
      }
    };

    backend.post('/emails/contact/send', data)
      .then(result =>
        this.setState({result, sendStatus: 'SENT'})
      )
      .catch(e => this.setState({error: e, sendStatus: 'ERROR'}));
  },
  onResetSendStatus() {
    this.setState({sendStatus: 'NEW'});
  },
});
