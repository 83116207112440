import React from 'react';
import style from './Gallery.scss';
import {createConnector} from 'cartiv';
import {api} from '../../api';
import {Icon} from '../Icon/Icon';
import {Carousel} from 'react-responsive-carousel';

const connect = createConnector(React);

export class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedIndex: 0};
    this.style = style;
    // this.change = this.change.bind(this);
    // setInterval(() => this.change(-1), 5000);
  }

  // change(diff) {
  //   const l = this.props.entries.length;
  //   const curIdx = this.state.selectedIndex;
  //   const nextIndex = (curIdx + diff + l) % l;
  //   // console.log(curIdx, nextIndex, diff, l);
  //   this.setState({
  //     selectedIndex: nextIndex,
  //   });
  //   onClick={e => this.change(e.pageX > window.innerWidth / 2 ? +1 : -1)}
  // }

  render() {
    if (!this.props.entries) {
      return null;
    }
    return (
      <div className="Gallery">
        <Carousel
          className="images"
          infiniteLoop={true}
          showArrows
          showStatus={false}
          showIndicators
          showThumbs={false}
          useKeyboardArrows
          autoPlay={false}
          stopOnHover
          dynamicHeight={false}
          emulateTouch
          centerMode={false}
        >{
          this.props.entries.map((element, index) => (<div
            className="Image"
            style={{
              backgroundImage: `url(${element.imageUrl}?w=800)`,
            }}
            key={index}
          >
            <h4 className="title">{element.title}</h4>
          </div>))}
        </Carousel>
      </div>
    );
  }
}
