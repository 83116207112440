import React from 'react';
import {createConnector} from 'cartiv';
import style from "./PrivacyPage.scss";
import {api} from '../../api';
import {ComponentsStore} from "../../stores/ComponentsStore";
import {mockStore} from "../../lib/Mock";

const connect = createConnector(React);

@connect(mockStore(ComponentsStore))
export class PrivacyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {components: ComponentsStore.state.components};
    this.dummy = style;
    api.Components.onLoad('page', 'privacy');
  }

  render() {
    if (!this.state.components || !this.state.components.page || !this.state.components.page.privacy) return null;
    return (
      <div className="PrivacyPage Page">
        <div className="container">
          <h1>Privacy</h1>
          <div className="row">
            <div className="col-xs-12" dangerouslySetInnerHTML={{__html: this.state.components.page.privacy.content}}>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

