import React from 'react';
import {createConnector} from 'cartiv';
import {HomePageStore} from './HomePageStore';
import {ComponentsStore} from "../../stores/ComponentsStore";
import {api} from "../../api";
import {Map} from "../../elements/Map/Map";
import {Gallery} from "../../elements/Gallery/Gallery";
import {ContactForm} from "../../elements/ContactForm/ContactForm";
import {mockStore} from "../../lib/Mock";
import {Clients} from "../../elements/Clients/Clients";
import style from './HomePage.scss';

const connect = createConnector(React);

@connect(HomePageStore)
@connect(mockStore(ComponentsStore))
export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    /* Initialize it with the initial state of the connected ComponentStore */
    this.state = {components: ComponentsStore.state.components};
    this.style = style;
    api.Components.onLoad('page', 'home');
    let isIosSafariServer = (
      global.isServer
      && /iOS|iPad|iPhone/.test(global.userAgent)
    );
    let isIosSafariClient = (
      typeof window !== 'undefined'
      && typeof window.navigator !== 'undefined'
      && /iOS|iPad|iPhone/.test(window.navigator.userAgent)
    );
    this.isIosSafari = isIosSafariServer || isIosSafariClient;
    this.easterEgg = this.easterEgg.bind(this);
    this.eggCounter = 5;
  }

  easterEgg(e) {
    console.log(this.eggCounter);
    if (this.eggCounter > 0) {
      this.eggCounter--;
      return;
    }
    Array.prototype.forEach.call(
      document.querySelectorAll('img'),
      i => i.src = 'https://blob.app.justrocket.cloud/justrocket/justrocket-backend/5eb256cb-dc94-4889-ac2d-75e30ebbdab3.jpeg?w=256'
    );

    Array.prototype.forEach.call(
      document.querySelectorAll('div[style*=background-image]'),
      i => i.style.backgroundImage = 'url(https://blob.app.justrocket.cloud/justrocket/justrocket-backend/5eb256cb-dc94-4889-ac2d-75e30ebbdab3.jpeg?w=512)'
    );
    e.currentTarget.innerText = 'Because'
  }

  scrollToTestimonial(e, delta) {
    const testimonials = document.querySelectorAll('.Testimonial');
    const onscreenItems = Array.prototype.filter.call(testimonials, t => {
      const r = t.getBoundingClientRect();
      return r.left >= 0 && r.left < window.innerWidth
        || r.right >= 0 && r.right < window.innerWidth;
    });
    const getOnScreenW = t => {
      const r = t.getBoundingClientRect();
      let w = 0;
      if (r.left < 0) {
        w = r.right;
      } else if (r.right > window.innerWidth) {
        w = window.innerWidth - r.left;
      }
      return w;
    };
    const t = onscreenItems.sort((a, b) => {
      return getOnScreenW(a) - getOnScreenW(b);
    })[0];
    if (t) {
      const idx = Array.prototype.indexOf.call(testimonials, t);
      const nt = testimonials[Math.max(0, Math.min(testimonials.length - 1, idx + delta))];
      if (nt) {
        const scrollX = t.parentNode.scrollLeft + nt.getBoundingClientRect().left;
        t.parentNode.scrollTo(scrollX, 0);
      }
    }
  }

  render() {
    if (!this.state.components || !this.state.components.page || !this.state.components.page.home) return null;
    let heroW = window.innerWidth * window.devicePixelRatio;
    if (!heroW) {
      heroW = 1200;
    }
    return (
      <div className="HomePage Page">
        <div className="container fixed">
          {/*<Logo icon={true} className={`special`}/>*/}
        </div>
        <div className={`Hero`} id={`hero`}
             style={{
               backgroundImage: `url(${this.state.components.page.home.hero.backgroundImageUrl}?w=${heroW})`,
               backgroundAttachment: this.isIosSafari ? 'scroll' : 'fixed',
             }}
        >
          <h1 className={`greeting`} dangerouslySetInnerHTML={{__html: this.state.components.page.home.hero.greeting}}/>
        </div>
        <div className="Intro Section" id={`intro`}>
          <div className="container">

            <h2 className="section-title" onClick={this.easterEgg}>{this.state.components.page.home.intro.title}</h2>
            <div className="section-content"
                 dangerouslySetInnerHTML={{__html: this.state.components.page.home.intro.content}}/>
            <div className="CallToAction">
              <div className="right-action" dangerouslySetInnerHTML={{
                __html:
                this.state.components.page.home.callToAction
              }}/>
            </div>
          </div>
        </div>
        <div className="Benefits Section" id={`benefits`}>
          <div className="container">

            <h2 className="section-title">{this.state.components.page.home.benefits.title}</h2>
            <div className="benefits row">
              {this.state.components.page.home.benefits.entries.map(benefit => {
                return (
                  <div className="Benefit col-xs-12 col-sm-5 col-md-4" key={benefit.title}>
                    <h4 className="title">{benefit.title}</h4>
                    <div className="description" dangerouslySetInnerHTML={{__html: benefit.description}}/>
                    <div className={`callToAction`} dangerouslySetInnerHTML={{__html: benefit.callToAction}}/>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="Accelerator Section" id={`accelerator`}>
          <div className="container">
            <h2 className="section-title">{this.state.components.page.home.accelerator.title}</h2>
            <div className="section-content"
                 dangerouslySetInnerHTML={{__html: this.state.components.page.home.accelerator.content}}/>
            <div className="CallToAction">
              <div className="right-action" dangerouslySetInnerHTML={{
                __html:
                this.state.components.page.home.accelerator.callToAction
              }}/>
            </div>
            <div className="benefits row">
              {this.state.components.page.home.accelerator.entries.map(entry => {
                return (
                  <div className="Benefit col-xs-12 col-sm-5 col-md-4" key={entry.title}>
                    <h4 className="title">{entry.title}</h4>
                    <div className="description" dangerouslySetInnerHTML={{__html: entry.description}}/>
                    <div className={`callToAction`} dangerouslySetInnerHTML={{__html: entry.callToAction}}/>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="Memberships Section" id={`memberships`}>
          <div className="container">
            <h2 className="section-title">{this.state.components.page.home.memberships.title}</h2>
            <div className="section-content row">
              {this.state.components.page.home.memberships.entries.map(membership => {
                return <div className="Membership col-xs-10 col-sm-5 col-md-4 col-lg-3" key={membership.title}>
                  <div className="header">
                    <h4 className="title">{membership.title}</h4>
                    <div className="price">{membership.price}</div>
                    <small className="info">/ {membership.period} excl. vat</small>
                  </div>
                  <ul className="features">
                    {membership.features.map(feature => {
                      return <li
                        className={`${feature.important ? 'important' : ''} feature`}
                        key={feature.name}
                      >
                        {feature.name}
                      </li>;
                    })}
                  </ul>
                </div>
              })}
            </div>
          </div>
          <div className="container">
            <h2 className="section-title">{this.state.components.page.home.dinzler.title}</h2>
            <div className="section-content"
                 dangerouslySetInnerHTML={{__html: this.state.components.page.home.dinzler.content}}/>
            <div className="CallToAction">
              <div className="right-action" dangerouslySetInnerHTML={{
                __html:
                this.state.components.page.home.dinzler.callToAction
              }}/>
            </div>
            <div className="benefits row">
              {this.state.components.page.home.dinzler.entries.map(entry => {
                return (
                  <div className="Benefit col-xs-12 col-sm-5 col-md-4" key={entry.title}>
                    <h4 className="title">{entry.title}</h4>
                    <div className="description" dangerouslySetInnerHTML={{__html: entry.description}}/>
                    <div className={`callToAction`} dangerouslySetInnerHTML={{__html: entry.callToAction}}/>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="Testimonials Section" id={`testimonials`}>
          <div className="container">

            <h2 className="section-title">{this.state.components.page.home.testimonials.title}</h2>
            <div className="section-content row">
              {this.state.components.page.home.testimonials.entries.map(testimonial => {
                return (
                  <div className="Testimonial col-xs-10 col-sm-8 col-md-5"
                       key={testimonial.person.name}>
                    <div className="content">{testimonial.content}</div>
                    <div className="Person">
                      <img src={`${testimonial.person.imageUrl}`} className="Image"/>
                      <div className="name-label">
                        <h5 className="name">{testimonial.person.name}</h5>
                        <small className="label" dangerouslySetInnerHTML={{__html: testimonial.person.label}}/>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <button type="button" className="control-arrow control-next" onClick={e => this.scrollToTestimonial(e, +1)}/>
          <button type="button" className="control-arrow control-prev" onClick={e => this.scrollToTestimonial(e, -1)}/>
        </div>
        <div className="Gallery Section" id={`gallery`}>
          <div className="container">

            <h2 className="section-title">{this.state.components.page.home.gallery.title}</h2>
            <div className="section-content">
              <Gallery entries={this.state.components.page.home.gallery.entries}/>
            </div>
          </div>
        </div>

        <div className="Teams Section" id={`teams`}>
          <div className="container">

            <h2 className="section-title">{this.state.components.page.home.teams.title}</h2>
            <div className="section-content">
              <Clients entries={this.state.components.page.home.teams.entries}/>
            </div>
          </div>
        </div>
        <div className="Contact Section" id={`contact`}>
          <div className="container">
            <div className="section-content row">
              <div className={`col-xs-12 col-sm-10 col-md-6`}>
                <h2 className="section-title">{this.state.components.page.home.contact.title}</h2>
                <ContactForm className={'col-xs-12 col-md-10'}/>
              </div>
              <div className="Map col-xs-12 col-sm-10 col-md-6">
                <Map
                  pins={this.state.components.page.home.map.pins}
                  pinImage={this.state.components.page.home.map.pinImage}
                />
                <div className={`map-info`}
                     dangerouslySetInnerHTML={{__html: this.state.components.page.home.map.button}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const a = {
  "_type": "Group",
  "spec": {
    "contactForm": {"_type": "Group", "spec": {"form": {"_type": "Group", "spec": {"send": "String"}}}},
    "footer": {
      "_type": "Group",
      "spec": {
        "imprint": {"_type": "Group", "spec": {"label": "String"}},
        "privacy": {"_type": "Group", "spec": {"label": "String"}}
      }
    }
  }
};
