import React from 'react';
import {createConnector} from 'cartiv';
import {api} from '../../api';
import {ResizeStore} from '../../stores/ResizeStore';
import {Footer} from '../Footer/Footer';
import style from './Layout.scss';
import {Menu} from "../Header/Menu";
import {ComponentsStore} from "../../stores/ComponentsStore";
import {mockStore} from "../../lib/Mock";

const connect = createConnector(React);


@connect(ResizeStore)
@connect(mockStore(ComponentsStore))
export class Layout extends React.Component {
  constructor(props) {
    super(props);
    /* Initialize it with the initial state of the connected ComponentStore */
    this.state = {components: ComponentsStore.state.components};
    this.dummy = style;
    api.resize.onRequestScreenSize();
    api.resize.onStartListen();
    api.Components.onLoad('layout', 'main');
  }

  componentWillUnmount() {
    api.resize.onStopListen();
  }

  render() {
    let headerLinks = [];
    let footerLinks = [];
    let bannerContent = '#Neuland is now&nbsp;\n' +
      '      <a href="https://base-coworking.com"\n' +
      '      >\n' +
      '        <strong>BASE</strong>\n' +
      '        <span> MUC</span></a>\n' +
      '      &nbsp;part of&nbsp;\n' +
      '      <a href="https://base-coworking.com">\n' +
      '        <strong>BASE</strong>-Coworking.com</a>\n' +
      '    ';
    if (this.state.components.layout && this.state.components.layout.main) {
      headerLinks = this.state.components.layout.main.header.links;
      footerLinks = this.state.components.layout.main.footer.links;
      bannerContent = this.state.components.layout.main.banner || bannerContent;
    }
    if (typeof document !== 'undefined') {
      if (this.state.mobileSize) {
        document.body.parentElement.classList.add('mobile');
        document.body.parentElement.classList.remove('desktop');
      } else {
        document.body.parentElement.classList.add('desktop');
        document.body.parentElement.classList.remove('mobile');
      }
    }
    return (
      <div className="Layout">
        <Menu {...this.props} links={headerLinks} mobileSize={this.state.mobileSize}/>
        {this.props.children}
        <Footer sites={footerLinks}/>
      </div>
    );
  }
};
