import React from 'react';
import style from './Logo.scss';
import {Link} from 'react-router-dom';
import {Icon} from "../Icon/Icon";

export class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.style = style;
  }

  render() {
    return (
      <Link to="/"
            className={`Logo logo-wrapper ${this.props.className} ${this.props.iconOnly ? 'small' : ''} ${this.props.fullAddress ? 'fullAddress' : ''}`}>
        <Icon value={`logo`}/>
        {!this.props.noText ? (<span className="text">
          <span className="base">base</span>
          <span className="location">muc</span>
          {!this.props.iconOnly ? (

            this.props.fullAddress ?
              <div
                className="subline">
                <div className="line">
                  Blütenstraße 15
                </div>
                <div className="line">
                  80799 München
                </div>
              </div>
              : <span
                className="subline">Co-working</span>
          ) : null}
          </span>) : null}
      </Link>
    );
  }
}
