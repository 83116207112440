import React from 'react';
import {Link} from 'react-router-dom';
import {createConnector} from 'cartiv';
import {style} from './BurgerMenu.scss';
import {BurgerButton} from './BurgerButton';
import {Logo} from './Logo';

const connect = createConnector(React);


let startX = -1;

export class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showFixedHeader: false};
    this.dummy = style;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.beforeNavigate = this.beforeNavigate.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }
    // TODO reenable touch swipe
    // document.addEventListener('touchmove', this.onTouchMove);
    // document.addEventListener('touchstart', this.onTouchStart);
    // document.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') {
      return;
    }
    // document.removeEventListener('touchmove', this.onTouchMove);
    // document.removeEventListener('touchstart', this.onTouchStart);
    // document.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll() {
    if (typeof window === 'undefined') {
      return;
    }
    if (window.pageYOffset > 200 && this.state.showFixedHeader === false) {
      this.setState({showFixedHeader: true});
    } else if (window.pageYOffset < 200 && this.state.showFixedHeader === true) {
      this.setState({showFixedHeader: false});
    }
  }

  onTouchStart(event) {
    if (this.state.show === true && !this.hasClass(event.target, 'BurgerMenu')) {
      this.setState({show: false});
      return;
    }
    if (!this.hasClass(event.target, 'tabs.long') && (!this.hasClass(event.target, 'map'))) {
      startX = event.touches[0].pageX;
    }
  }

  onTouchEnd(event) {
    startX = -1;
  }

  onTouchMove(event) {
    if (startX !== -1 && startX - event.touches[0].pageX > window.innerWidth / 2.5) {
      this.setState({show: true});
    }
  }

  onClick(e) {
    switch (e.target.attributes.name.value) {
      case 'show':
        this.setState({show: true});
        break;
      case 'hide':
        this.setState({show: false});
        break;
      default:
        break;
    }
  }

  toggleMenu(e) {
    const menu = !this.state.show;
    this.setState({show: menu});
  }

  beforeNavigate(e) {
    window.scrollTo(0, 0);
    this.setState({show: false});
  }

  hasClass(element, className) {
    const regex = new RegExp(`\\b${className}\\b`);
    do {
      if (regex.exec(element.className)) {
        return true;
      }
      element = element.parentNode;
    } while (element);
    return false;
  }

  render() {
    const currentPath = `#${this.props.location.pathname}`;
    return (
      <div className="BurgerMenu">
        <BurgerButton close={this.state.show} onClick={this.toggleMenu}/>
        <div className={`burger-fixed-header ${this.state.showFixedHeader ? 'show' : ''} `}>
          <Logo iconOnly/>
          <BurgerButton close={this.state.show} onClick={this.toggleMenu}/>
        </div>
        <ul className={`menu ${this.state.show ? 'show' : ''}`}>
          {this.props.links.map(site =>
            (<li
              className={`${site.className}-menu-element ${currentPath === site.path ? 'selected' : ''} menu-element`}
              key={site.path}
              name={site.path}
            >
              <Link to={site.path} onClick={this.beforeNavigate}>{site.label}</Link>
            </li>))}
          <BurgerButton close={this.state.show} onClick={this.toggleMenu}/>
        </ul>
      </div>
    );
  }
}
