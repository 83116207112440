import {animateElement, scrollTo} from "./animate";

export const GlobalClickHandler = {
  /**
   * initializes the global click handler
   */
  init() {
    if (!global.isServer
      && typeof document !== 'undefined'
      && typeof document.addEventListener !== 'undefined') {
      /**
       *  all links that are embedded through the data from the BE shall
       *  be checked and if it's an external link
       *  clicking on it shall open a new window
       */
      document.addEventListener('click', (e) => {
        let aEl = e.target;
        while (aEl && `${aEl.tagName}`.toLowerCase() !== 'a') {
          aEl = aEl.parentElement;
        }
        if (aEl && aEl.getAttribute('target') !== '_blank' &&
          (`${aEl.getAttribute('class')}`).indexOf('no-route') === -1) {
          const url = aEl.getAttribute('href');
          if (/https?:\/\//.test(url)) {
            e.preventDefault();
            // open external links in new window
            window.open(url);
          } else if (/#.*$/.test(url)) {
            // scroll to anchor
            if (url.startsWith('#') || url.replace(/#.*$/, '') === window.location.pathname) {
              e.preventDefault();
            }
            setTimeout(() => {
              const anchor = url.match(/#.*$/)[0];
              const domEl = document.querySelector(anchor);
              let targetY = 0;
              if (domEl) {
                //Not supported: domEl.scrollIntoView({behavior: 'smooth'});
                targetY = domEl.offsetTop - 100;
              }
              animateElement({
                target: window,
                duration: 500,
                property: 'pageYOffset', // scrollY does not work on IE11
                to: targetY,
                callback: (o) => {
                  const targetY = (o.from + o.diff * o.percent);
                  o.target.scrollTo(0, targetY);
                },
              });
            }, 150);
          }
        }
      });
    }
  },
};
