import {polyfill} from 'es6-promise';

export const initPromisePolyfill = function () {
  polyfill();

  if (typeof Promise !== 'undefined' && typeof Promise.prototype.finally === 'undefined') {
    Promise.prototype.finally = function (onFinally) {
      const self = this;
      const wrapper = function (res, err) {
        setTimeout(() => onFinally.call(self, [res, err]), 1);
      };
      return this.then(
        r => wrapper(r, null) || r,
        err => {
          wrapper(null, err);
          throw err;
        });
    };
  }
};
