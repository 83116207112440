import React from 'react';
import {style} from './Clients.scss';

export class Clients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const entries = (this.props.entries);
    const displayH = 64/*~4em*/ * (window.devicePixelRatio || 1);
    return (
      <div className="Clients">
        {entries.map((client, idx) => (
          <a href={client.url} target="_blank"
             className="Client" key={idx}
          style={{
            backgroundImage: `url(${client.logo}?h=${displayH})`
          }}>
            <span className="name">
            {client.name}
            </span>
          </a>
        ))}
      </div>
    );
  }
}


