import React from 'react';
import {hot} from 'react-hot-loader';
import {Route} from 'react-router';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import style from '../shared/styles/main.scss';
import {AppRoutes} from '../shared/AppRoutes';


export class ColdApp extends React.Component {
  constructor(props) {
    super(props);
    this.dummy = style;
  }

  render() {
    return (
      <BrowserRouter>
        <Route path="/" render={(props) => {
          document.body.className = `router-${props.location.pathname.replace(/\//g, '-') || 'root'}`;
          return (<AppRoutes {...props}/>);
        }}/>
      </BrowserRouter>
    );
  }
}

export const App = hot(module)(ColdApp);

