import React from 'react';
import {Switch, Route, Redirect} from 'react-router';
import {createConnector} from 'cartiv';
import {api} from './api';
import {Layout} from "./elements/Layout/Layout";

import {HomePage} from "./pages/Home/HomePage";
import {PrivacyPage} from "./pages/Privacy/PrivacyPage";
import {ImprintPage} from "./pages/Imprint/ImprintPage";
import {Responsive} from "./pages/Responsive/Responsive";
import {NotFoundPage} from "./pages/NotFound/NotFoundPage";
import {GlobalClickHandler} from "./lib/GlobalClickHandler";

let history = null;

/**
 * @deprecated
 * @param address
 */
export const navigateTo = function (address) {
  if (global.isServer) {
    throw `Don't call navigateTo on server side`;
  }
  api.Analytics.onEvent('pageview', address);
  if (!history && typeof window !== 'undefined' && window.history) {
    // window.history would only change the url
    // const stateObj = {};
    // const title = '';
    console.log('failing to navigate');
    // window.history.pushState(stateObj, title, address);
  } else {
    console.log('navigation deprecated');
    history.push(address);
  }
};

GlobalClickHandler.init();
const connect = createConnector(React);

export class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (<Route path="/" render={() => {

      return (<Layout {...this.props}>
        <Switch>
          <Route path="/c/home" component={HomePage}/>
          <Route path="/c/privacy" component={PrivacyPage}/>
          <Route path="/c/imprint" component={ImprintPage}/>
          <Route path="/responsive" component={Responsive}/>
          <Route path="/" component={HomePage}/>
          <Route path="*" component={NotFoundPage}/>
        </Switch>
      </Layout>);
    }}
    />);
  }
}
