import React from 'react';
import style from './ContactForm.scss';
import {createConnector} from 'cartiv';
import {api} from '../../api';
import {clone} from "../../lib/clone";
import {ContactFormStore} from './ContactFormStore';
import {ComponentsStore} from "../../stores/ComponentsStore";
import {mockStore} from "../../lib/Mock";
import {StringInput} from "../FormControls/StringInput";
import {TextInput} from "../FormControls/TextInput";
import {BooleanInput} from "../FormControls/BooleanInput";

const connect = createConnector(React);

@connect(ContactFormStore)
@connect(mockStore(ComponentsStore))
export class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendStatus: 'NEW',
      components: ComponentsStore.state.components,
      inputData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      }
    };
    this.style = style;
    this.onChange = this.onChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    api.Components.onLoad('element', 'contactForm');
  }

  onChange(name, value) {
    const inputData = this.state.inputData;
    inputData[name] = value;
    this.setState({inputData});
  }

  sendEmail(e) {
    e.preventDefault();
    if (!this.state.inputData.agree) {
      alert('Please agree to be contacted.');
      return;
    }

    let contactData = clone(this.state.inputData);

    api.ContactForm.onSendContactForm(contactData);
    this.setState({name: '', email: '', phone: '', message: '', buttonPressed: 1});
  }

  renderStateOverlays() {
    if (this.state.sendStatus !== 'NEW') {
      if (this.state.sendStatus === 'ERROR' && !this.timeout) {
        this.timeout = setTimeout(() => {
          api.ContactForm.onResetSendStatus();
          this.timeout = null;
        }, 5000);
      }
      return <div className="send-status-overlay">
        <div className="message">
          {this.state.sendStatus}.
        </div>
        <div className={`details`}>
          {this.state.components.element.contactForm.friendlyNote || 'We\'ll get back to you...'}
        </div>
      </div>;
    }
    return <div className="send-status-overlay"/>;
  }

  render() {
    if (
      !this.state.components.element
      || !this.state.components.element.contactForm
    ) return null;

    return (
      <div className={`ContactForm ${this.props.className} ${this.state.sendStatus}`}>
        {this.renderStateOverlays()}
        <form onSubmit={this.sendEmail} className={`form`}>
          <StringInput name="name"
                       value={this.state.inputData.name}
                       entityDefinition={{}}
                       onChange={v => this.onChange('name', v)}/>
          <StringInput name="email"
                       value={this.state.inputData.email}
                       entityDefinition={{_type: 'Email'}}
                       onChange={v => this.onChange('email', v)}/>
          <StringInput name="phone"
                       value={this.state.inputData.phone}
                       entityDefinition={{_type: 'Phone'}}
                       onChange={v => this.onChange('phone', v)}/>
          <TextInput name="message"
                     value={this.state.inputData.message}
                     entityDefinition={{}}
                     onChange={v => this.onChange('message', v)}
          />
          <BooleanInput
            name="agree"
            value={this.state.inputData.agree}
            onChange={v => this.onChange('agree', v)}
            entityDefinition={{
              label: "I agree that the data above is correct and JR Spaces & Events may contact me.\n" +
                "          We will not share the contact data with any third parties."
            }}
          />
          <div className="form-actions">
            <input className="button" type="submit"
                   value={this.state.components.element.contactForm.send}/>
          </div>
        </form>
      </div>
    );
  }
}
