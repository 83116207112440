import * as bp from 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {initPromisePolyfill} from '../shared/lib/Promise';
import {App} from './App';
import {AnalyticsStore} from '../shared/lib/AnalyticsStore';

ReactDOM.hydrate(
  <App/>,
  document.getElementById('react-view'),
);

initPromisePolyfill();
AnalyticsStore.init();
