import {config} from './config';

require('es6-promise').polyfill();
require('isomorphic-fetch');


let BE_API = config.backendUrl;

const handleRespCode = function (p) {
  return p.then((resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      if (resp.status === 204) {
        return resp;
      }
      return resp.json();
    }
    throw resp;
  });
};
const handleError = function (p) {
  return p.catch((e) => {
    console.error(e);
    throw e;
  });
};

const buildFetchOptions = function () {
  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json; charset=utf-8',
      'jrc-domain': 'base-coworking.com',
      'jrc-group': 'DEFAULT_GROUP'
    },
    credentials: 'include', // include, same-origin, *omit
    mode: 'cors', // no-cors, *same-origin
    redirect: 'follow', // *manual, error
    referrer: 'no-referrer', // *client
  };

  return {
    withHeader(name, value) {
      fetchOptions.headers[name] = value;
      return this;
    },
    body(body) {
      fetchOptions.body = body;
      return this;
    },
    method(method) {
      if (['POST', 'GET'].indexOf(method) !== -1) {
        fetchOptions.method = method;
      } else {
        fetchOptions.method = 'POST';
        fetchOptions.headers['X-HTTP-Method-Override'] = method;
      }
      return this;
    },
    build() {
      return fetchOptions;
    },
  };
};

const DUMMY_PROMISE = {
  then: () => DUMMY_PROMISE, "catch": () => DUMMY_PROMISE, "finally": () => DUMMY_PROMISE
};

export const backend = {
  query(type, query, forceOnServer = false) {
    const itsSettingsObj = Object.keys(query).every(k => ['q', 'p', 'l', 's'].indexOf(k) !== -1);
    const querySettings = itsSettingsObj ? query : {q: query};
    return this.get(`/collection/${type}/`, querySettings, forceOnServer);
  },
  get(path, data, forceOnServer = false) {
    let params = '';
    if (global.isServer && !forceOnServer) {
      return DUMMY_PROMISE;
    }
    if (typeof data === 'object') {
      params = Object.keys(data)
        .reduce((s, k) => `${s}&${k}=${encodeURIComponent(JSON.stringify(data[k]))}`, '')
        // strip away the first &
        .substr(1);
    } else if (data) {
      params = data;
    }
    let p = fetch(`${BE_API}${path}${params === '' ? '' : '?' + params}`,
      buildFetchOptions()
        .method('GET')
        .build());
    p = handleRespCode(p);
    p = handleError(p);
    return p;
  },
  post(path, data, forceOnServer = false) {
    let p = fetch(`${BE_API}${path}`,
      buildFetchOptions()
        .method('POST')
        .body(JSON.stringify(data))
        .build()
    );
    p = handleRespCode(p);
    p = handleError(p);
    return p;
  },
  put(path, data, forceOnServer = false) {
    let p = fetch(`${BE_API}${path}`,
      buildFetchOptions()
        .method('PUT')
        .body(JSON.stringify(data))
        .build()
    );
    p = handleRespCode(p);
    p = handleError(p);
    return p;
  },
  delete(path, data, forceOnServer = false) {
    let p = fetch(`${BE_API}${path}`,
      buildFetchOptions()
        .method('DELETE')
        .body(JSON.stringify(data))
        .build()
    );
    p = handleRespCode(p);
    p = handleError(p);
    return p;
  },
};
