import React from 'react';
import style from './Responsive.scss';
import {createConnector} from 'cartiv';
import {api} from '../../api';
// import {ResponsiveStore} from './ResponsiveStore';
const connect = createConnector(React);

const LocalLogo = () => <a href="https://justrocket.de" target={`_blank`} className="logo-wrapper">
  <img src="https://justrocket.de/images/logo_w.png" alt="JustRocket" className="logo"/>
</a>;


// @connect(ResponsiveStore)
export class Responsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: .5,
      screens: [
        {
          width: 320,
          height: 480,
        },
        {
          width: 480,
          height: 720,
        },
        {
          width: 720,
          height: 480,
        },
        {
          width: 1024,
          height: 768,
        },
        {
          width: 1200,
          height: 800,
          scale: .4,
        },
        {
          width: 1920,
          height: 1080,
          scale: .3,
        },
      ],
      percent: 0,
    };
    this.readWindowTopPerc = this.readWindowTopPerc.bind(this);
    this.zoomScreen = this.zoomScreen.bind(this);
    this.scrollAll = this.scrollAll.bind(this);
    this.onIframeLoaded = this.onIframeLoaded.bind(this);
    this.style = style;
  }

  componentDidMount() {
    this.readWindowTopPerc();
    if (typeof document !== 'undefinde') {
      document.addEventListener('keypress', e => {
        if (e.code.indexOf('Digit') === 0) {
          const idx = parseInt(e.code.split('Digit')[1]) - 1;
          this.zoomScreen(document.querySelectorAll('.Screen')[idx], idx);
        } else if (e.code === 'Escape') {
          this.setState({zoom: null});
        }
      });
    }
  }

  scrollAll(percent) {
    Array.prototype.forEach.call(window.frames, frame => {
      if (frame.window === this.activeFrameWindow) {
        return;
      }
      const bodyHeight = frame.document.body.getBoundingClientRect().height;
      if (bodyHeight > frame.window.innerHeight) {
        const tY = (bodyHeight - frame.window.innerHeight) * percent;
        frame.scrollTo(0, tY);
      }
    });
  }

  onIframeLoaded(e) {
    const iframeDocument = e.target.contentDocument;
    const iframeWindow = e.target.contentWindow;
    iframeDocument.addEventListener('mousemove', e => {
      this.activeFrameWindow = iframeWindow;
    });
    iframeDocument.addEventListener('scroll', e => {
      if (this.activeFrameWindow !== iframeWindow) {
        return;
      }
      const bodyHeight = iframeDocument.body.getBoundingClientRect().height;
      if (bodyHeight > iframeWindow.innerHeight) {
        const percent = (iframeWindow.scrollY) / (bodyHeight - iframeWindow.innerHeight);
        this.percent = Math.round(percent * 100);
        this.scrollAll(percent);
      }
    });
  }

  readWindowTopPerc() {
    this.setState({percent: window.top.percent || 0});
    // requestAnimationFrame(() => this.readWindowTopPerc());
  }

  zoomScreen(currentTarget, idx) {
    let timeout = 5;
    if (this.state.zoom) {
      timeout = 500;
      this.setState({zoom: null});
      if (this.zommedScreenIdx === idx) {
        this.zommedScreenIdx = -1;
        return;
      }
    }
    this.zommedScreenIdx = idx;
    setTimeout(() => {
      const r = currentTarget.getBoundingClientRect();
      const tx = Math.round(window.innerWidth / 2 - (r.left + r.width / 2));
      const ty = Math.round(window.innerHeight / 2 - (r.top + r.height / 2));
      const scale = window.innerHeight / r.height;
      // const tx =
      // const transformString = `translate(${tx}px, ${ty}px) scale(${scale})`;
      const transformString = `scale(${scale}) translate(${tx}px, ${ty}px)`;
      this.setState({
        zoom: {
          // transformOrigin: `${tox}, ${toy}`,
          transform: transformString,
        },
      });
    }, timeout);
  }

  render() {
    return (
      <div className="Responsive">
        <div className={`zoomPane ${this.state.zoom ? 'zoomed' : null}`} style={this.state.zoom}>
          {this.state.screens.map((screenSize, index) => {
            const scale = screenSize.scale || this.state.scale;
            return (<div
              className={`Screen`}
              onClick={e => this.zoomScreen(e.currentTarget, index)}
              key={`${screenSize.width}x${screenSize.height}`}
              style={{
                width: screenSize.width * scale,
                height: screenSize.height * scale,
              }}
            >
              <div className="shortcut-key zoom-in">Press <span className="button">{index + 1}</span></div>
              <div className="shortcut-key zoom-out">Press <span className="button">esc</span></div>
              <iframe
                onLoad={this.onIframeLoaded}
                width={screenSize.width}
                height={screenSize.height}
                style={{transform: `scale(${scale})`}}
                src={`/`}
              />
              <div className="size">
                {screenSize.width} x {screenSize.height}
              </div>
            </div>);
          })}
          <div className="ControlBar">
            <LocalLogo/>
          </div>
        </div>
      </div>
    );
  }
}
