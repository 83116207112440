var Promise = require('promise');

let counter = 0;
let scriptMap = new Map();

export const cacheScript = (function (glbl) {
  return function (scripts) {
    const Cache = {};

    Cache._onLoad = function (key) {
      return (cb) => {
        let stored = scriptMap.get(key);
        if (stored) {
          stored.promise.then(() => {
            stored.error ? cb(stored.error) : cb(null, stored)
          });
        }
      };
    };

    Cache._scriptTag = (key, src) => {
      if (!scriptMap.has(key)) {
        const tag = document.createElement('script');
        const promise = new Promise((resolve, reject) => {
          const body = document.getElementsByTagName('body')[0];

          tag.type = 'text/javascript';
          tag.async = false; // Load in order

          const cbName = `loaderCB${counter++}${Date.now()}`;

          const cleanup = () => {
            if (glbl[cbName] && typeof glbl[cbName] === 'function') {
              glbl[cbName] = null;
            }
          };

          const handleResult = (state) => {
            return (evt) => {
              const stored = scriptMap.get(key);
              if (state === 'loaded') {
                stored.resolved = true;
                resolve(src);
              } else if (state === 'error') {
                stored.errored = true;
                reject(evt);
              }

              cleanup();
            };
          };

          tag.onload = handleResult('loaded');
          tag.onerror = handleResult('error');
          tag.onreadystatechange = () => {
            handleResult(tag.readyState);
          };

          // Pick off callback, if there is one
          if (src.match(/callback=CALLBACK_NAME/)) {
            src = src.replace(/(callback=)[^\&]+/, `$1${cbName}`);
            glbl[cbName] = tag.onload;
          } else {
            tag.addEventListener('load', tag.onload);
          }
          tag.addEventListener('error', tag.onerror);

          tag.src = src;
          body.appendChild(tag);
          return tag;
        });
        let initialState = {
          loaded: false,
          error: false,
          promise: promise,
          tag,
        };
        scriptMap.set(key, initialState);
      }
      return scriptMap.get(key);
    };

    Object.keys(scripts).forEach(function(key) {
      const script = scripts[key];
      Cache[key] = {
        tag: Cache._scriptTag(key, script),
        onLoad: Cache._onLoad(key)
      };
    });

    return Cache;
  };
})(global);
