import React from 'react';
import {Link} from 'react-router-dom';
import style from './NotFoundPage.scss';

export class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.style = style;
  }

  render() {
    return (
      <div className="NotFoundPage Page">
        <div className="centered">
          <h1>404</h1>
          <p>Page not found</p>
          <Link to={'/'} className={`button`}>
            Go back home
          </Link>
        </div>
      </div>
    );
  }
}
