let configObject;

// local ui-test env
configObject = {
  'backendUrl': 'http://localhost:5000/api',
  'backendFileUrl': 'http://localhost:5004',
};

// local backend
configObject = {
  'backendUrl': 'http://localhost:8080/api',
  'backendFileUrl': 'http://localhost:5004',
};
// configObject = {
// 'backendUrl': 'http://192.168.2.147:8080/api',
// 'backendFileUrl': 'http://192.168.2.147:5004',
// };
//

// develop backend
// configObject = {
  // "backendUrl": "https://jap-api.develop.justrocket.cloud/api",
  // "backendFileUrl": "https://blob.develop.justrocket.cloud"
// };

// master backend
configObject = {
  'backendUrl': 'https://jap-api.app.justrocket.cloud/api',
  'backendFileUrl': 'https://blob.app.justrocket.cloud',
};

export default configObject;
