import {api} from '../api';

const report = function (thrownError, extractedData) {
  // TODO report errors
  // api.Notifications.onNotify({
  //   message: `An issue occured ${JSON.stringify(extractedData || '')}`,
  //   type: 'danger',
  //   details: thrownError,
  // });
  console.error(thrownError, extractedData);
};

export const genericErrorHandler = function (err) {
  if (err && err.json && err.body) {
    err.json().then(data => {
      report(err, data);
    }).catch(e2 => report(null, err || e2));
  } else {
    report(null, err);
  }
};
