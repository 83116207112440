import {createStore} from 'cartiv';
import {backend} from '../backend_api';
import {api} from '../api';
import {clone} from '../lib/clone';
import {genericErrorHandler} from './Common';
import {cloneMock, mockObject} from "../lib/Mock";

export const ComponentsStore =
  createStore({
    api,
    name: 'Components',
  }, {
    getInitialState() {
      let initial = {};
      if (typeof ssrData !== 'undefined' && ssrData && ssrData.components) {
        initial = mockObject(ssrData.components, 'components');
      }
      return {
        loading: 0,
        /**
         * type: {
         *    name: DATA
         * }
         *
         * Example:
         *
         * page: {
         *   home: {
         *     welcome: {
         *       title: '',
         *       paragraph: '',
         *     }
         *   }
         * }
         */
        components: initial,
      };
    },
    onLoad(componentType, componentName, force = false) {
      if (this.state.components[componentType]
        && this.state.components[componentType][componentName]
        && !this.state.components[componentType][componentName]._mocked
        && force === false) {
        // don't load already loaded content;
        return;
      }
      const newState = {loading: this.state.loading + 1};
      this.setState(newState);

      backend.get('/collection/Component/', {q: {_type: componentType, name: componentName}})
        .then((result) => {
          if (!result.content.length) {
            throw {
              status: 404,
              message: `Component ${componentName} of type ${componentType} not found`,
            };
          }
          const newComponents = cloneMock(this.state.components);
          newComponents[componentType] = newComponents[componentType] || {};
          let data = result.content[0]._entityData;
          newComponents[componentType][componentName] = mockObject(data, `components.${componentType}.${componentName}`);
          this.setState({
            loading: this.state.loading - 1,
            components: newComponents,
          });
        })
        .catch((e) => {
          this.setState({
            loading: this.state.loading - 1,
          });
          genericErrorHandler(e);
        });
    },

    xDomainUpdate(type, name, value) {
      const components = clone(this.state.components);
      components[type] = components[type] || [];
      components[type][name] = value;
      this.setState({components});
    }
  });


if (typeof document !== 'undefined') {
  const enableXDPC = function (name, object) {
    window.addEventListener('message', function (e) {
      if (e
        && e.data
        && e.data.type === 'xdpc'
        && e.data.target === name
        && typeof object[e.data.method] !== 'undefined') {
        object[e.data.method].apply(object, e.data.args)
      }
    }, false);
  };
  enableXDPC('ComponentsStore', ComponentsStore);
}
