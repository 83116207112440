import React from 'react';
import * as style from './BooleanInput.scss';

// @connect(I18nStore)
export class BooleanInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dummy = style;
  }

  render() {
    const value = typeof this.props.value !== 'undefined' && this.props.value !== null ?
      this.props.value : !!this.props.defaultValue;

    return (
      <div
        className={`${this.props.name} BooleanInput form-group form-check form-check-flat ${this.props.fieldClasses.groupClass} `}>
        <label
          className={`form-control-label form-check-label`}
          htmlFor={`${this.props.name}-form-control`}>
          <div className={`${this.props.fieldClasses.labelClass} label`}>
            {this.props.entityDefinition.label}
          </div>
          <div className={`input-wrapper ${this.props.fieldClasses.inputWrapperClass}`}>
            <input
              type="checkbox"
              name={this.props.name}
              id={`${this.props.name}-form-control`}
              className={`${this.props.name} form-control form-check-input ${this.props.fieldClasses.inputClass}`}
              placeholder={this.props.entityDefinition.placeholder}
              onChange={e => {
                this.props.onChange(e.target.checked);
              }}
              checked={value}
            />
            <i className="input-helper "/>
            {this.props.entityDefinition.details ?
              <small className="details form-text text-muted">{this.props.entityDefinition.details}</small> : null
            }
          </div>
        </label>
      </div>
    );
  }
}

BooleanInput.defaultProps = {
  value: false,
  defaultValue: false,
  label: null,
  fieldClasses: {
    groupClass: '',
    labelClass: '',
    inputClass: '',
    inputWrapperClass: '',
  },
  entityDefinition: {},
  onChange: (v) => console.log(v),
};
