import React from 'react';
import {Link} from 'react-router-dom';
import {createConnector} from 'cartiv';
import {style} from './Footer.scss';
import {api} from '../../api';
import {ComponentsStore} from '../../stores/ComponentsStore';
import {Icon} from "../Icon/Icon";
import {DomainStore} from "../../stores/DomainStore";
import {Logo} from "../Header/Logo";
import {mockStore} from "../../lib/Mock";

const connect = createConnector(React);

@connect(mockStore(ComponentsStore))
@connect(mockStore(DomainStore))
export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: DomainStore.state.domain,
      components: ComponentsStore.state.components
    };
    api.Domain.onGetDomainInfo();
    this.dummy = style;
    api.Components.onLoad('element', 'footer');
    this.resizeLogo = this.resizeLogo.bind(this);
  }

  resizeLogo(footerContainerDomEl) {
    const logo = footerContainerDomEl.querySelector('.Logo');
    const boundingClientRect = logo.getBoundingClientRect();
    const targetW = boundingClientRect.width;
    const logoW =
      logo.querySelector('.text').getBoundingClientRect().right -
      boundingClientRect.left;

    if (logoW > window.innerWidth) {
      const scale = targetW / logoW;
      console.log('scaling', `scale(${scale})`);
      logo.style.transformOrigin = `0 0`;
      logo.style.transform = `scale(${scale})`;
    }
  }

  render() {
    if (
      !this.state.components
      || !this.state.components.element
      || !this.state.components.element.footer
      || !this.state.domain
      || !this.state.domain.basic
    ) return null;
    return (
      <div className="Footer">
        <div className="container" ref={this.resizeLogo}
        >
          <Logo fullAddress/>
          <div className="right-wrapper col-xs-12 col-sm-8 col-md-6">
            <div className="copyright">
              &copy;{new Date().getFullYear()} {this.state.domain.basic.company}
            </div>
            <div className="links">
              <Link to={'/c/imprint'}>{this.state.components.element.footer.imprint.label}</Link>
              <span className="and">&</span>
              <Link to={'/c/privacy'}>{this.state.components.element.footer.privacy.label}</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

