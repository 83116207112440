import {createStore} from 'cartiv';
import {api} from '../api';
import {config} from '../config';
import {DomainStore} from "../stores/DomainStore";
import {cacheScript} from "../elements/Map/ScriptCache";

const initMobileAnalytics = function (analyticsId) {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  ga('create', analyticsId, {'storage': 'none', 'clientId': device.uuid});
  ga('set', 'checkProtocolTask', null);
  ga('set', 'anonymizeIp', true);
  ga('set', 'forceSSL', true);
};

const initWebAnalytics = function (analyticsId) {
  analyticsId = 'UA-118701835-1';
  window.dataLayer = window.dataLayer || [];
  const gtag = function () {
    dataLayer.push(arguments);
  };
  gtag('js', new Date());
  gtag('config', analyticsId);
  const cache = cacheScript({
    [`analytics-${analyticsId}`]: `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`,
  });
  cache[`analytics-${analyticsId}`].onLoad = function (s, e) {
    console.log(s, e);
  };
};


export const AnalyticsStore = createStore({
  api: api,
  name: 'Analytics',
}, {
  getInitialState() {
    return {};
  },
  init() {
    if (!global.isServer
      && typeof window !== 'undefined') {
      DomainStore.get('basic.googleAnalyticsId').then(
        googleAnalyticsID => {
          if (googleAnalyticsID) {
            initWebAnalytics(googleAnalyticsID)
          }
        }
      );
    }
  },
  onPageView(path) {
    this.onEvent('pageview', path);
  },
  onEvent(type, data) {
    const appType = typeof cordova === 'undefined' ? 'webapp' : 'mobileapp';
    if (typeof ga !== 'undefined') {
      if (type === 'pageview') {
        // report the pageview as standard pageview
        ga('send', 'pageview', {'title': appType + ': Page ' + data, 'page': data});
        data = {action: 'open ' + data};
      }
      // report event
      ga('send', 'event', {
        'eventCategory': type,
        'eventAction': data.action || 'action',
        'eventLabel': data.label || 'label',
        'eventValue': data.value || 0
      });
    }
  }
});

if (typeof module !== 'undefined') {
  createStore.allowHMR(module, AnalyticsStore);
}
