import {createStore} from 'cartiv';
import {backend} from '../../backend_api';
import {api} from '../../api';
import {clone} from '../../lib/clone';
import {genericErrorHandler} from '../../stores/Common';

export const HomePageStore = createStore({
  api,
  name: 'HomPage',
}, {
  getInitialState() {
    return {
      loading: false,
      data: [],
    };
  },
  onLoadHomPage(){
    this.setState({
      loading: true,
    });
    backend.get(`/collection/Component`).then(result=> {
      this.setState({
        loading:false,
        data: result.content,
      });
    }).catch(genericErrorHandler);
  },
});
