import {createStore} from 'cartiv';
import {backend} from '../backend_api';
import {api} from '../api';
import {extend} from "../lib/utils";
import {genericErrorHandler} from '../stores/Common';

export const DOMAIN_SETTINGS_DATA = {
  "basic" : {
    "title" : "Co-Working Space Munich | BASE Co-Working",
    "logo" : "https://base-coworking.com/images/favicon.png",
    "favicon" : "https://base-coworking.com/images/favicon.png",
    "description" : "Co-Working Space Munich | BASE Co-Working",
    "googleAnalyticsId" : "UA-118701835-1",
    "company" : "JR Spaces & Events "
  },
  "seo" : {
    "description" : "Das perfekte Büro für Startups und Unternehmen in München - Einzelplätze, Team Spaces und Konferenzräume. Highspeed Internet, Drucker, Community Events und mehr",
    "keywords" : "BASE Co-Working "
  },
  "og" : {
    "url" : "https://base-coworking.com",
    "title" : "BASE Co-Working",
    "description" : "BASE Co-Working",
    "image" : "https://file.justrocket.cloud/justrocket-backend/base-coworking.com/blob_5ca995ffa3422aaf6852aafcef8043ab.jpeg"
  },
  "emails" : {
    "emailAddresses" : {
      "noReply" : {
        "address" : "noreply-backend@justrocket.cloud",
        "name" : "BASE Co-Working"
      },
      "contact" : {
        "address" : "info@base-coworking.com",
        "name" : "BASE Co-Working"
      }
    },
    "emailTexts" : {
      "signup" : {
        "subject" : "Welcome to BASE Co-Working",
        "html" : "Welcome to BASE Co-Working html"
      }
    }
  },
  "contact" : {
    "name" : "Manuel Wesch",
    "phone" : "+49 89 21539091",
    "email" : "info@base-coworking.com"
  },
  "adminUIConfig" : {
    "blogposts" : false,
    "menu" : []
  },
  "permissionDefinition" : {
    "rolePermissions" : []
  }
};

export const DomainStore = createStore({
  api,
  name: 'Domain',
}, {
  getInitialState() {
    return {
      domain: typeof ssrData !== 'undefined' && ssrData && ssrData.domain ?
        ssrData.domain : null
    };
  },
  get(key) {
    if (this.state.domain && this.state.domain.basic) {
      const domain = this.state.domain;
      const value = key.split('.').reduce((obj, k) => obj ? obj[k] : null, domain);
      return Promise.resolve(value);
    }

    return this.onGetDomainInfo().then(domain => {
      return key.split('.').reduce((obj, k) => obj ? obj[k] : null, domain);
    });
  },
  onGetDomainInfo() {
    return backend.get(`/domains/current`).then(result => {

      let resultDomain = extend(
        DOMAIN_SETTINGS_DATA,
        result
      );

      this.setState({
        domain: resultDomain,
      });
      return resultDomain;
    })
      .catch(genericErrorHandler);
  },
});
