import React from 'react';
import {style} from './FixedHeader.scss';
import {Link} from "react-router-dom";
import {Logo} from "./Logo";

export class FixedHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.dummy = style;
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined' || global.isServer) {
      return;
    }
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    if (typeof window === 'undefined' || global.isServer) {
      return;
    }
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll() {
    if (typeof window === 'undefined') {
      return;
    }
    if (window.pageYOffset > 200 && this.state.show === false) {
      this.setState({show: true});
    }

    if (window.pageYOffset < 200 && this.state.show === true) {
      this.setState({show: false});
    }
  }

  onClick() {
    if (typeof window === 'undefined') {
      return;
    }
    window.scrollTo(0, 0);
  }

  render() {
    const currentPath = this.props.location.pathname;
    return (
      <div className={`fixedHeader ${this.state.show === true ? 'show' : ''}`}>
        <div className="container">
          <Logo iconOnly/>
          <div className="menu">
            {this.props.links.map(site =>
              <div
                className={`${currentPath === site.path ? 'selected' : ''} ${site.className}-menu-element menu-element`}
                key={site.path}
              >
                {site.path.startsWith('#') ? <a href={site.path}>{site.label}</a> :
                  <Link to={`${site.path}`}>{site.label}</Link>}
                <div className={`${currentPath === site.path ? 'selected' : ''} menu-bg`}/>
              </div>,
            )}
            {/*<div className="menu-element language-switcher-wrapper">*/}
            {/*<LanguageSwitcher/>*/}
            {/*<div className="menu-bg"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  }
}
