let BE_API = "http://justrocket-backend/api";
let FILE_API = "http://justrocket-jrfile";

if ((typeof window !== 'undefined') && (/localhost|(\d+\.){3}/.test(window.location.toString()))) {
  BE_API = "http://localhost:5000/api";
  FILE_API = "http://localhost:5004";
}


let configObject = {
  "backendUrl": BE_API,
  "backendFileUrl": FILE_API
};

export default configObject;
