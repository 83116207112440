import develop from './develop';
import local from './local';
import master from './master';
import uitest from './uitest';

const buildType = process.env.BUILD_TYPE || (typeof window !== 'undefined' ? window._env : 'local');

const configs = {
  develop,
  local,
  master,
  uitest,
};

export const config = configs[buildType] || local;
// console.log(`Started with config for ${buildType} env.`, config);

